import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { selectUserInfo } from '@core/store/user-info/user-info.selectors';
import { select, Store } from '@ngrx/store';
import { appRoutes } from 'src/app/shared/enums/app-routes.enum';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { IAppState } from '../store/app.reducers';

@Injectable()
export class ViewModeGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private store: Store<IAppState>) { }

  canActivate(): Observable<boolean | UrlTree> {
    return this.store.pipe(select(selectUserInfo))
      .pipe(
        filter(userInfo => !!userInfo && userInfo.viewMode !== undefined),
        first(),
        map(userInfo => {
          if (userInfo.viewMode) {
            return this.router.parseUrl(appRoutes.dashboard.fullPath);
          }

          return true;
        }),
      );
  }

  canActivateChild(): Observable<boolean | UrlTree> {
    return this.canActivate();
  }

}
