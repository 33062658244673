import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NavigationEnd, Params, Router} from '@angular/router';
import {ApplicationInsightsService} from '@core/services/application-insights.service';
import {
  AuthService,
  SESSION_COUNT,
  USER_LOGIN_TYPE,
} from '@core/services/auth.service';
import {CacheService} from '@core/services/cache.service';
import {NotificationService} from '@core/services/notification.service';
import {TokenExpiryService} from '@core/services/token-expiry.service';
import {IAppState} from '@core/store/app.reducers';
import {getCurrentInfo} from '@core/store/user-info/user-info.actions';
import {environment} from '@env/environment';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AppBlockerService} from 'src/app/modules/shared/app-blocker/services/app-blocker.service';
import {appRoutes} from 'src/app/shared/enums/app-routes.enum';
import {qLoadRouteAfterLogin, qRelogin} from 'src/app/shared/query-param-ids';
import {concat, EMPTY, from, Observable, of, throwError, timer} from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  filter,
  first,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  blockAppUsage,
  login,
  loginError,
  loginSuccess,
  logout,
  refreshToken,
  reloginAfterIdle,
  reloginAfterIdleSuccess,
  semiLogout,
} from './auth.actions';
import {AccountHttpService} from '@core/https/account-http.service';
import {LoginTypeEnum} from 'src/app/shared/enums/login-type.enum';

@Injectable()
export class AuthEffects {
  login$ = createEffect(() =>
    this._actions$.pipe(
      ofType(login),
      exhaustMap(({userCredential, loadRoute, surfconext}) => {
        let httpRequest$: Observable<any>;
        if (surfconext) {
          httpRequest$ =
            this._accountHttpService.loginWithSurfConext(surfconext);
        } else {
          httpRequest$ = this._accountHttpService.login(userCredential);
        }
        return httpRequest$.pipe(
          map((userToken) =>
            loginSuccess({
              userToken,
              loadRoute,
              loginType: !!surfconext
                ? LoginTypeEnum.SURFConext
                : LoginTypeEnum.Regular,
            }),
          ),
          catchError((httpError) => of(loginError({httpError}))),
        );
      }),
    ),
  );

  loginSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(loginSuccess),
      tap(({userToken, loadRoute, fallbackRouteEnabled, loginType}) => {
        this._applicationInsightsService.logEvent('Logged in successfully');

        this._tokenExpiryService.setExpiration(
          userToken.expiration,
          environment.idle.forcedReloginAfterNoResponseInSeconds * 1000,
        );

        this._authService.setUserTokenAndSession(userToken);

        if (loginType === undefined) {
          loginType =
            JSON.parse(localStorage.getItem(USER_LOGIN_TYPE)) ||
            LoginTypeEnum.Regular;
        }

        localStorage.setItem(USER_LOGIN_TYPE, JSON.stringify(loginType));
      }),
      exhaustMap((payload) => {
        // Used when the user was idled and use the SURFConext to relogin
        const appSessionCount: string = localStorage.getItem(SESSION_COUNT);
        if (
          window.location.pathname.includes('/auth/login') &&
          payload.loginType === LoginTypeEnum.SURFConext &&
          +appSessionCount > 1
        ) {
          // We need to wait a bit before we close this window
          // until the main window/tab has been re-logged in successfully
          return timer(2000).pipe(
            map(() => payload),
            tap(() => window.close()),
          );
        }
        return of(payload);
      }),
      map(({loadRoute, fallbackRouteEnabled}) =>
        getCurrentInfo({loadRoute, fallbackRouteEnabled}),
      ),
    ),
  );

  loginError$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(loginError),
        tap(({httpError}) => {
          const {error} = httpError;
          this._matSnackBar.open(error.title, '', {
            duration: 5000,
            panelClass: 'snackbar-error',
          });
        }),
      ),
    {dispatch: false},
  );

  logout$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(logout),
        switchMap((payload) => {
          if (
            payload.waitNavigationEnd &&
            this._router.getCurrentNavigation() !== null
          ) {
            return this._router.events.pipe(
              filter((e) => e instanceof NavigationEnd),
              map(() => payload),
              first(),
            );
          }
          return of(payload);
        }),
        concatMap((payload) =>
          concat(
            from(this._notificationService.close()), // Close SignalR Notification service
            this._accountHttpService.logout(), // Invalidate User Token in Server
          ).pipe(map(() => payload)),
        ),
        tap(() => {
          this._authService.clearEPALocalStorageKeys();

          this._matDialog.closeAll();
          this._cacheService.purge();
          this._tokenExpiryService.clear();

          this._applicationInsightsService.logEvent('Logged out');
          this._applicationInsightsService.clearUserId();
        }),
        tap((payload) => {
          const queryParams: Params = {};
          queryParams[qRelogin] = payload.reLogin;
          queryParams[qLoadRouteAfterLogin] = payload.loadRoute;

          this._router
            .navigate([appRoutes.login.fullPath], {
              queryParams,
              queryParamsHandling: payload.queryParamsHandling,
            })
            .then(() => {
              if (payload.userToken) {
                // Switch Portfolio, View Student Portfolio
                this._store.dispatch(
                  loginSuccess({
                    userToken: payload.userToken,
                    loadRoute: payload.loadRoute,
                  }),
                );
              }
            })
            .then(() => {
              if (payload.hardReloadAfter) {
                location.reload();
              }
            });
        }),
      ),
    {dispatch: false},
  );

  blockAppUsage$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(blockAppUsage),
        tap(() => {
          this._applicationInsightsService.logEvent('App blocked');

          // Do not show app blocker on public pages
          const baseUrl = this._router.url.split('?')[0];
          const isBlackListedPage =
            AppBlockerService.cacheBlackListedPages.indexOf(baseUrl) >= 0;

          if (!isBlackListedPage) {
            this._appBlockerService.show(true);
          }
        }),
      ),
    {dispatch: false},
  );

  semiLogout$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(semiLogout),
        tap(() => {
          this._tokenExpiryService.clear();
          this._applicationInsightsService.logEvent('User semi-logged out');

          this._notificationService
            .close()
            .then(() => {
              this._authService.clearUserTokenLS();
            })
            .catch((error) => throwError('SignalR Closing Error: ' + error));
        }),
      ),
    {dispatch: false},
  );

  refreshToken$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(refreshToken),
        switchMap(() => {
          if (!this._authService.refreshTokenInProgress) {
            return this._authService.refreshToken();
          }

          // Avoid simultaneous refresh token requests
          return EMPTY;
        }),
      ),
    {dispatch: false},
  );

  reloginAfterIdle$ = createEffect(() =>
    this._actions$.pipe(
      ofType(reloginAfterIdle),
      switchMap(({userCredential}) =>
        this._accountHttpService.login(userCredential).pipe(
          map((userToken) => reloginAfterIdleSuccess({userToken})),
          catchError((httpError) => of(loginError({httpError}))),
        ),
      ),
    ),
  );

  reloginAfterIdleSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(reloginAfterIdleSuccess),
        tap(({userToken}) => {
          this._applicationInsightsService.logEvent('Logged in successfully');

          this._authService.setUserTokenAndSession(userToken);
          this._tokenExpiryService.setExpiration(
            userToken.expiration,
            environment.idle.forcedReloginAfterNoResponseInSeconds * 1000,
          );
          this._notificationService.init();

          this._appBlockerService.show(false);
        }),
      ),
    {dispatch: false},
  );

  constructor(
    private _store: Store<IAppState>,
    private _actions$: Actions,
    private _router: Router,
    private _authService: AuthService,
    private _matDialog: MatDialog,
    private _cacheService: CacheService,
    private _tokenExpiryService: TokenExpiryService,
    private _accountHttpService: AccountHttpService,
    private _notificationService: NotificationService,
    private _applicationInsightsService: ApplicationInsightsService,
    private _appBlockerService: AppBlockerService,
    private _matSnackBar: MatSnackBar,
  ) {
  }
}
