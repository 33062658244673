import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService, USER_LOGIN_TYPE } from '@core/services/auth.service';
import { IAppState } from '@core/store/app.reducers';
import { reloginAfterIdle } from '@core/store/auth/auth.actions';
import {
  selectAuthError,
  selectAuthIsLoading,
} from '@core/store/auth/auth.selectors';
import {
  selectIsViewModeByHT,
  selectProfilePhotoUrl,
  selectUserNameNoTitle,
  selectViewModeByName,
  selectViewModeByProfilePhotoUrl,
} from '@core/store/user-info/user-info.selectors';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { environment } from '@env/environment';
import { LoginTypeEnum } from 'src/app/shared/enums/login-type.enum';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'xf-app-blocker-overlay',
  templateUrl: './app-blocker-overlay.component.html',
  styleUrls: ['./app-blocker-overlay.component.scss'],
})
export class AppBlockerOverlayComponent implements OnInit, OnDestroy {
  @ViewChild('passwordInput')
  passwordInput: ElementRef;

  loginForm: FormGroup;
  profilePhotoUrl$: Observable<string>;
  userFullName$: Observable<string>;
  isAuthLoading$: Observable<boolean>;
  authError$: Observable<string>;
  viewModeByHT$: Observable<boolean>;
  viewModeByName$: Observable<string>;
  viewModeByProfilePhotoUrl$: Observable<string>;

  hide = true;

  loginType: string;
  loginTypeEnum = LoginTypeEnum;

  get username() {
    return this.loginForm.get('username');
  }
  get password() {
    return this.loginForm.get('password');
  }

  private _unsubscribe = new Subject();

  constructor(
    private store: Store<IAppState>,
    private authService: AuthService,
    private formBuilder: FormBuilder
  ) {
    this.loginType = JSON.parse(localStorage.getItem(USER_LOGIN_TYPE));
    this._buildLoginForm();
  }

  ngOnInit() {
    this.userFullName$ = this.store.pipe(select(selectUserNameNoTitle));
    this.profilePhotoUrl$ = this.store.pipe(select(selectProfilePhotoUrl));
    this.isAuthLoading$ = this.store.pipe(select(selectAuthIsLoading));
    this.authError$ = this.store.pipe(select(selectAuthError));

    this.viewModeByHT$ = this.store.pipe(select(selectIsViewModeByHT));
    this.viewModeByName$ = this.store.pipe(select(selectViewModeByName));
    this.viewModeByProfilePhotoUrl$ = this.store.pipe(
      select(selectViewModeByProfilePhotoUrl)
    );

    this.store
      .pipe(select(selectAuthError), takeUntil(this._unsubscribe))
      .subscribe((error) => {
        if (error?.length) {
          this.password.enable();
        }
      });

    this.username.patchValue(this.authService.usernameLS());

    setTimeout(() => {
      this.passwordInput.nativeElement.focus();
    }, 200);
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  onLogin() {
    if (this.loginForm.invalid) {
      return;
    }

    this.password.disable();

    const userCredential = this.loginForm.value;
    this.store.dispatch(reloginAfterIdle({ userCredential }));
  }

  onSignInViaSurfConext() {
    window.open(environment.surfConextUrl, '_blank', 'popup');
  }

  private _buildLoginForm() {
    this.loginForm = this.formBuilder.group({
      username: [undefined, [Validators.required]],
      password: [undefined, [Validators.required]],
    });
  }
}
