import { createAction, props } from '@ngrx/store';
import { Language } from 'src/app/shared/models/language.model';

export const getLanguages = createAction(
  '[App] Get languages',
);

export const getLanguagesSuccess = createAction(
  '[App] Get languages (success)',
  props<{ languages: Language[] }>(),
);

export const setLocalizationsLoaded = createAction(
  '[App] Localization loaded',
  props<{ loaded: boolean }>(),
);
