import { createAction, props } from '@ngrx/store';
import { UserInfo } from 'src/app/shared/models/user-info.model';

export const getCurrentInfo = createAction(
  '[App] Get current user info',
  props<{ loadRoute?: string; redirectUserToDashboard?: boolean, fallbackRouteEnabled?: boolean }>(),
);

export const getCurrentInfoSuccess = createAction(
  '[App] Get current user info (success)',
  props<{
    userInfo: UserInfo;
    loadRoute?: string;
    redirectUserToDashboard?: boolean,
    fallbackRouteEnabled?: boolean }>(),
);

export const getCurrentInfoError = createAction(
  '[App] Get current user info (error)',
);
