import {createAction, props} from '@ngrx/store';
import {EmployeeAssignedEPA, EmployeeAssignedInput, EmployeeItemUpdate} from 'src/app/shared/models/employee-assigned-epa.model';
import {EPAInformation} from 'src/app/shared/models/epa-information.model';
import {BasicEPAInformation, ChartOverview, EpaLibraryData, libraryDataInput} from 'src/app/shared/models/epa-library-data.model';

export const getChartOverview = createAction(
  '[App] Get Chart Overview ',
  props<{ departmentId: number }>(),
);

export const getChartOverviewSuccess = createAction(
  '[App] Get Chart Overview (success)',
  props<{ chartOverview: ChartOverview }>(),
);

export const getLibraryData = createAction(
  '[App] Get epa library data ',
  props<{ input: libraryDataInput }>(),
);

export const getLibraryDataSuccess = createAction(
  '[App] Get epa library data (success)',
  props<{ epaLibraryData: EpaLibraryData }>(),
);

export const getBasicEPAInformation = createAction(
  '[App] Get Basic EPA Information data',
  props<{ epaId: number; preventCashing?: number, dataType: string }>(),
);

export const getBasicEPAInformationSuccess = createAction(
  '[App] Get Basic EPA Information data (success)',
  props<{ basicEPAInformation: BasicEPAInformation }>(),
);

export const getEPAInformation = createAction(
  '[App] Get EPA Information data',
  props<{ epaId: number; preventCashing?: number, dataType: string }>(),
);

export const getEPAInformationSuccess = createAction(
  '[App] Get EPA Information data (success)',
  props<{ epaInformation: EPAInformation }>(),
);

export const getEmployeeAssignedEPA = createAction(
  '[App] Get Employee’s with Assigned EPA Status',
  props<{ input: EmployeeAssignedInput }>(),
);

export const getEmployeeAssignedEPASuccess = createAction(
  '[App] Get Employee’s with Assigned EPA Status (success)',
  props<{ data: EmployeeAssignedEPA }>(),
);

export const updateEmployeesAssignedEpa = createAction(
  '[App] Put Employee’s Assigned EPA ',
  props<{
    epaId: number;
    employeesUpdate: EmployeeItemUpdate[];
    employeeAssignedEPA: EmployeeAssignedEPA;
  }>(),
);

export const updateEmployeesAssignedEpaSuccess = createAction(
  '[App] Put Employee’s Assigned EPA (Success)',
  props<{ response: any; employeeAssignedEPA: EmployeeAssignedEPA }>(),
);

export const setDataLoaded = createAction(
  '[App] Data loaded',
  props<{ loaded: boolean }>(),
);

export const setUpdateLoaded = createAction(
  '[App] Data Update Loaded',
  props<{ updateLoaded: boolean }>(),
);

export const setBasicInfoLoaded = createAction(
  '[App] Data Basic Info  Loaded',
  props<{ loadedBasicInfo: boolean }>(),
);
