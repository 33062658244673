import { Action, createReducer, on } from '@ngrx/store';
import { Language } from 'src/app/shared/models/language.model';
import { getLanguagesSuccess, setLocalizationsLoaded } from './language.actions';

export interface ILanguageState {
  languages: Language[];
  localizationLoaded: boolean;
}

const initialLanguageState: ILanguageState = {
  languages: undefined,
  localizationLoaded: false,
};

const reducer = createReducer(
  initialLanguageState,
  on(getLanguagesSuccess, (state, action) => ({ ...state, languages: action.languages })),
  on(setLocalizationsLoaded, (state, action) => ({ ...state, localizationLoaded: action.loaded })),
);

export function languageReducer(state: ILanguageState, action: Action) {
  return reducer(state, action);
}
