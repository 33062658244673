import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Lookup } from 'src/app/shared/models/lookup.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AdminCategoryHttpService {

  constructor(private http: HttpClient) {}

  getCategories(): Observable<Lookup[]> {
    return this.http.get<any>('admin/Category').pipe(map((response) => response.items));
  }
}
