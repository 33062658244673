import { Injectable } from '@angular/core';
import { appRoutes } from 'src/app/shared/enums/app-routes.enum';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class AppBlockerService {

  static readonly cacheBlackListedPages = [
    appRoutes.auth.fullPath,
    appRoutes.login.fullPath,
    appRoutes.logout.fullPath,
    appRoutes.changeLanguage.fullPath,
    appRoutes.forgotPassword.fullPath,
    appRoutes.formUpload.fullPath,
    appRoutes.anonymous.fullPath,
    appRoutes.token.fullPath,
    appRoutes.thankYou.fullPath,
    appRoutes.invitation.fullPath,
    appRoutes.confirmation.fullPath,
    appRoutes.declined.fullPath,
    appRoutes.emailConfirmed.fullPath,
    appRoutes.formCompleted.fullPath,
    appRoutes.formDeleted.fullPath,
    appRoutes.formVoid.fullPath,
    appRoutes.contact.publicFullPath,
    appRoutes.about.publicFullPath,
    appRoutes.privacy.publicFullPath,
    appRoutes.conditionTerms.publicFullPath,
    appRoutes.feedback.publicFullPath,
  ];

  get isShown$(): Observable<boolean> {
    return this._isShown$.asObservable();
  }

  private _isShown$ = new BehaviorSubject<boolean>(false);

  constructor() { }

  show(flag: boolean) {
    this._isShown$.next(flag);
  }

}
