import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { UrlService } from '@core/services/url.service';
import { IAppState } from '@core/store/app.reducers';
import { loginSuccess } from '@core/store/auth/auth.actions';
import { selectIsUserLoggedIn } from '@core/store/auth/auth.selectors';
import { select, Store } from '@ngrx/store';
import { appRoutes } from 'src/app/shared/enums/app-routes.enum';
import { qLoadRouteAfterLogin } from 'src/app/shared/query-param-ids';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private store: Store<IAppState>,
    private router: Router,
    private urlService: UrlService,
    private authService: AuthService,
  ) {}

  canActivate(activatedRoute: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.store.pipe(
      select(selectIsUserLoggedIn),
      first(),
      map(isLoggedIn => {
        if (isLoggedIn) { return true; }

        const isLoginPage = activatedRoute.url.map(x => x.path).indexOf(appRoutes.login.pathName) >= 0;

        if (!!this.authService.userTokenLS()) {
          this.store.dispatch(loginSuccess({
            userToken: this.authService.userTokenLS(),
            fallbackRouteEnabled: isLoginPage,
          }));
          return true;
        } else {
          if (isLoginPage) { return true; }

          const loadRoute = escape(this.urlService.getCurrentRouteWithQueryAndFragments());
          return this.router.parseUrl(`${ appRoutes.login.fullPath }?${ qLoadRouteAfterLogin }=${ loadRoute }`);
        }
      }),
    );
  }

  canActivateChild(activatedRoute: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.canActivate(activatedRoute);
  }

}
