import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterDepartmentDisplayed } from 'src/app/shared/models/checkbox-filter.model';

@Component({
  selector: 'xf-check-box-filter',
  templateUrl: './check-box-filter.component.html',
  styleUrls: ['./check-box-filter.component.scss'],
})
export class CheckBoxFilterComponent implements OnInit {
  @Output() filterSelectChanged = new EventEmitter<Array<number>>();
  @Input() filters;
  filtersDisplayed: FilterDepartmentDisplayed[] = [];

  @Input() selectedDepartmentIds: number[] = [];

  constructor() {}

  get getFilterSelected(): number[] {
    return this.filtersDisplayed
      .filter((f) => f.selected && f.id != 0)
      .map((f) => f.id);
  }

  ngOnInit(): void {
    this.getDataFilters();
    const filterSelected = this.getFilterSelected;
    this.filterSelectChanged.emit(filterSelected);
  }

  filterChanged($event, filter: FilterDepartmentDisplayed) {
    if (filter.id === 0) {
      this.filtersDisplayed.forEach((d) => (d.selected = $event));
    } else if (
      this.filtersDisplayed[0].id === 0 &&
      this.filtersDisplayed[0].selected &&
      !$event
    ) {
      this.filtersDisplayed[0].selected = $event;
    }
    const filterSelected = this.getFilterSelected;
    this.filterSelectChanged.emit(filterSelected);
  }

  getDataFilters() {
    this.filters.forEach((filter, i) => {
      let filterItem = new FilterDepartmentDisplayed();
      for (const key of Object.keys(filter)) {
        if (filter[key] || filter[key] === 0) {
          filterItem[key] = filter[key];
        }
      }
      this.filtersDisplayed.push(filterItem);
    });

    if (this.selectedDepartmentIds?.length) {
      this.filtersDisplayed?.forEach((dep) => {
        let find = this.selectedDepartmentIds.find((id) => dep.id === id);
        if (find) {
          dep.selected = true;
        } else {
          dep.selected = false;
        }
      });
    }
    if (this.filtersDisplayed?.length > 1) {
      const isAllSelected =
        this.selectedDepartmentIds?.length &&
        this.filtersDisplayed?.length === this.selectedDepartmentIds?.length;
      this.filtersDisplayed.unshift({
        id: 0,
        key: null,
        name: 'Select/Unselect all',
        selected: isAllSelected || !this.selectedDepartmentIds?.length,
      });
    }
  }
}
