import { Injectable } from '@angular/core';
import { IUserInfoState } from '@core/store/user-info/user-info.reducer';
import { MemberStatusEnum } from 'src/app/shared/enums/member-status.enum';
import { UserRole } from 'src/app/shared/enums/user-role.enum';
import {
  tooltipDisabledFeature1Key,
  tooltipDisabledFeature2Key,
  tooltipDisabledFeature3Key,
  tooltipDisabledFeature4Key,
  tooltipNoInternshipsKey
} from 'src/app/shared/localization-keys';
import { UserInfo } from 'src/app/shared/models/user-info.model';
import { LocalizationService } from './localization.service';

@Injectable()
export class FormAccessService {

  constructor(private localizationService: LocalizationService) {}

  canAccessForms(userInfo: IUserInfoState | UserInfo): boolean {
    const isUserRoleAuthenticated = userInfo.roleId === UserRole.Authenticated;
    const isUserRoleHeadTeacher = userInfo.roleId === UserRole.Manager;
    const isUserRoleStudent = userInfo.roleId === UserRole.Employee;
    const isMemberStatusCancelled = userInfo.groupInfo.memberStatus === MemberStatusEnum.Cancelled;
    const hasTrainingProgram = userInfo.groupInfo.hasTrainingProgram;

    const isReadOnly = ((isUserRoleHeadTeacher) && !userInfo.groupInfo.hasStudents) ||
      (isUserRoleStudent && (!userInfo.groupInfo.hasTeachers && !userInfo.groupInfo.hasHeadTeachers));

    return !(isUserRoleAuthenticated || userInfo.viewMode || isReadOnly || isMemberStatusCancelled
      || (isUserRoleStudent && !hasTrainingProgram));
  }

  getNoAccessMessage(userInfo: IUserInfoState | UserInfo): string {
    if (userInfo.viewMode) {
      return this.localizationService.getTranslationByKey(tooltipDisabledFeature3Key);
    } else if (userInfo.roleId === UserRole.Authenticated) {
      return this.localizationService.getTranslationByKey(tooltipDisabledFeature4Key);
    } else if (userInfo.groupInfo.memberStatus === MemberStatusEnum.Cancelled) {
      return this.localizationService.getTranslationByKey(tooltipDisabledFeature1Key);
    } else if (userInfo.roleId === UserRole.Employee && !userInfo.groupInfo.hasTrainingProgram) {
      return this.localizationService.getTranslationByKey(tooltipDisabledFeature2Key);
    } else if (userInfo.roleId === UserRole.Employee && !userInfo.hasInternshipSchedule) {
      return null;
    }

    return null;
  }
}
