import {Injectable} from '@angular/core';
import {CanActivate, Router, UrlTree} from '@angular/router';
import {FormAccessService} from '@core/services/form-access.service';
import {IAppState} from '@core/store/app.reducers';
import {selectUserInfo} from '@core/store/user-info/user-info.selectors';
import {select, Store} from '@ngrx/store';
import {appRoutes} from 'src/app/shared/enums/app-routes.enum';
import {Observable} from 'rxjs';
import {filter, first, map} from 'rxjs/operators';

@Injectable()
export class FormSubmissionGuard implements CanActivate {
  constructor(
    private router: Router,
    private formAccessService: FormAccessService,
    private store: Store<IAppState>) {
  }

  canActivate(): Observable<boolean | UrlTree> {
    return this.store.pipe(select(selectUserInfo))
      .pipe(
        filter(userInfo => !!userInfo && !!userInfo.roleId),
        first(),
        map(userInfo => {
          if (!this.formAccessService.canAccessForms(userInfo)) {
            return this.router.parseUrl(appRoutes.dashboard.fullPath);
          }
          return true;
        }),
      );
  }
}
