import { Component, OnDestroy, OnInit } from '@angular/core';
import { FiltersService } from '@core/services/filters.service';
import { IAppState } from '@core/store/app.reducers';
import {
  selectDashboardData,
  selectIsDashboardLoaded,
} from '@core/store/dashboard/dashboard.selectors';
import { Store, select } from '@ngrx/store';
import {
  DashboardTopDepartment,
  DashboardTopPerformer,
} from 'src/app/shared/models/dashboard-data';
import {
  DashboardPerformersAndDepartments,
  DashboardTopPerformerDisplayed,
} from 'src/app/shared/models/top-departments.model';
import { TopDepartmentsService } from 'src/app/shared/services/top-departments.service';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'xf-top-departments',
  templateUrl: './top-departments.component.html',
  styleUrls: ['./top-departments.component.scss'],
})
export class TopDepartmentsComponent implements OnInit, OnDestroy {
  isTopDepartments$: Observable<boolean>;
  isBottomDepartments$: Observable<boolean>;
  isTopPerformers$: Observable<boolean>;
  isBottomPerformers$: Observable<boolean>;

  topDepartments$: Observable<DashboardTopDepartment[]>;
  topPerformers: DashboardTopPerformer[];
  topPerformersDisplayed: DashboardTopPerformerDisplayed[] = [];
  bottomPerformers: DashboardPerformersAndDepartments[] = [];
  bottomDepartments: DashboardPerformersAndDepartments[] = [];
  textFilterPeriodView: string;
  isLoading: boolean = false;

  private _unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store<IAppState>,
    private topDepartmentsService: TopDepartmentsService,
    private filtersService: FiltersService
  ) {}

  ngOnInit() {
    this.isTopDepartments$ = this.topDepartmentsService.isTopDepartments$;
    this.isBottomDepartments$ = this.topDepartmentsService.isBottomDepartments$;
    this.isTopPerformers$ = this.topDepartmentsService.isTopPerformers$;
    this.isBottomPerformers$ = this.topDepartmentsService.isBottomPerformers$;

    this.filtersService.filterPeriodView$
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((filter) => (this.textFilterPeriodView = filter));

    this.topDepartments$ = this.store
      .pipe(select(selectDashboardData))
      .pipe(map((data) => data?.topDepartments));

    this.store
      .pipe(select(selectDashboardData))
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((data) => {
        if (!data) {
          return;
        }

        this.bottomPerformers = data?.bottomPerformers;
        this.topPerformers = data?.topPerformers;
        this.bottomDepartments = data?.bottomDepartments;
        this.setData(data?.topPerformers);
      });

    this.store
      .select(selectIsDashboardLoaded)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((isLoaded) => (this.isLoading = !isLoaded));
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  setData(topPerformers: DashboardTopPerformer[]) {
    this.topPerformersDisplayed = [];

    for (let topPerformer of topPerformers) {
      let displayed = new DashboardTopPerformerDisplayed();

      for (const key of Object.keys(topPerformer)) {
        displayed[key] = topPerformer[key];
      }

      if (!displayed.imageUrl?.toLowerCase().match(/\.(jpeg|jpg|png|gif)/g)) {
        displayed.imageUrl = null;
      }

      this.topPerformersDisplayed.push(displayed);
    }
  }
}
