import { InstitutionType } from '@management/user-database/shared/enums/institution-type.enum';
import { UserProfile } from 'src/app/shared/models/user-profile.model';

interface User {
  firstName?: string;
  middleName?: string;
  surName?: string;
  userTitle?: string;
}

export function getName(user: User, includeTitle = true): string {
  if (!user) {
    return '';
  }

  const {
    firstName = '',
    middleName = '',
    surName = '',
    userTitle = '',
  } = user || {};
  const suffixTitles = ['PA', 'MD', 'BSc', 'MSc', 'PhD'];
  const result = [firstName, middleName, surName].filter(x => !!x).join(' ');
  if (includeTitle) {
    if (suffixTitles.indexOf(userTitle) >= 0) {
      return `${ result }, ${ userTitle }`;
    } else if (!!userTitle) {
      return `${ userTitle } ${ result }`;
    }
  }
  return result;
}

export function getInstitutionName(userProfile: UserProfile) {
  if (!userProfile) {
    return '';
  }

  const { institutionType } = userProfile;
  switch (institutionType) {
    case InstitutionType.MBO:
      return userProfile.schoolName ?? '';
    case InstitutionType.Hospital:
      return userProfile.hospitalName ?? '';
    case InstitutionType.HBO:
      return userProfile.hboName ?? '';
    default:
      return '';
  }
}
