import { Injectable } from '@angular/core';
import { longMonthsName, shortMonthsName } from 'src/app/shared/enums/months.enum';
import { LocalizationService } from './localization.service';

@Injectable()
export class TranslateMonthService {

  private _languageCode: string;

  constructor(private localization: LocalizationService) {
    this._languageCode = this.localization.getLanguageCodeLS();
  }

  getMonthName(currentMonth: number, isLongMonthName: boolean) {
    if (isLongMonthName) {
      const translateMonthObs = longMonthsName[currentMonth];
      return translateMonthObs[this._languageCode];
    } else {
      const translateMonthObs = shortMonthsName[currentMonth];
      return translateMonthObs[this._languageCode];
    }
  }

}
