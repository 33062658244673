import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { ApplicationInsightsService } from '@core/services/application-insights.service';
import { LocalizationService } from '@core/services/localization.service';
import {
  ReloginNotificationDialogComponent
} from 'src/app/shared/components/relogin-notification-dialog/relogin-notification-dialog.component';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { ErrorCode } from 'src/app/shared/enums/error-code.enum';
import {
  reloadPageKey,
  somethingWentWrong,
  somethingWentWrongInTheServerKey,
  unauthorized
} from 'src/app/shared/localization-keys';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, first } from 'rxjs/operators';
import { appRoutes } from 'src/app/shared/enums/app-routes.enum';
import { FORMS } from 'src/app/shared/enums/forms.enum';
import { feedback360Routes } from '@feedback-360/enums/feedback-360-routes.enum';
import { AuthService } from '@core/services/auth.service';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

  private _reloginDialog: MatDialogRef<ReloginNotificationDialogComponent>;

  constructor(
    private _matSnackBar: MatSnackBar,
    private _matDialog: MatDialog,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _applicationInsightsService: ApplicationInsightsService,
    private _localizationService: LocalizationService,
    private _authService: AuthService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        const { error } = httpErrorResponse;
        const requestStatus: number = httpErrorResponse?.status || error?.status || 0;

        if (requestStatus >= 500) {
          this._matSnackBar.open(
            this._localizationService.getTranslationByKey(somethingWentWrongInTheServerKey),
            this._localizationService.getTranslationByKey(reloadPageKey), {
            panelClass: 'snackbar-error',
          })
            .onAction()
            .pipe(first())
            .subscribe(() => {
              window.location.reload();
            });

          this._router.events
            .pipe(filter(e => e instanceof NavigationStart), first())
            .subscribe(() => {
              this._matSnackBar.dismiss();
            });
        } else if (requestStatus === 401) {
          const { errorCode } = error || {};

          let errorMsgKey: string;
          let panelClass: string;
          let showSnackbar = true;

          if (errorCode === ErrorCode.MembershipRoleChanged && !this._reloginDialog) {
            this._reloginDialog = this._matDialog.open(ReloginNotificationDialogComponent, {
              panelClass: 'base-dialog',
              disableClose: true,
              closeOnNavigation: false,
              data: {
                hideClose: true,
                title: 'role_changed_title',
                message: 'role_changed_by_head_teacher',
              },
            });

            this._reloginDialog.afterClosed()
              .pipe(first())
              .subscribe(() => {
                this._reloginDialog = null;
              });
          } else if (errorCode === ErrorCode.SecurityTokenInvalidSignatureException ||
            errorCode === ErrorCode.InvalidOwnerOrSupervisor ||
            errorCode === ErrorCode.AnonymousUser_SubmissionInvalidAssessor ||
            errorCode === ErrorCode.AnonymousUser_SubmissionInvalidSupervisor ||
            errorCode === ErrorCode.AnonymousUser_SubmissionInvalidSupervisorOrAssessor) {

            errorMsgKey = unauthorized;
            panelClass = 'snackbar-error';

            if (errorCode === ErrorCode.AnonymousUser_SubmissionInvalidSupervisorOrAssessor) {
              showSnackbar = false;
              this._router.navigateByUrl(appRoutes.formCompleted.fullPath);
            } else {
              this._router.navigate([appRoutes.login.fullPath], {
                queryParams: { loadRouteAfterLogin: this._router.url },
              });
            }
          } else if (errorCode === ErrorCode.RequiredToLoginAndRedirectToSubmission) {
            const formUploadId = this._activatedRoute.snapshot.queryParams.formUploadId;
            const formId = error.formId;
            const formPaths = FORMS[+formId]?.routeName;

            errorMsgKey = somethingWentWrong;
            panelClass = 'snackbar-warning';

            if (formPaths) {
              let loadRouteAfterLogin: string;
              if (formId === 7) { // 360-feedback
                loadRouteAfterLogin = `/${ formPaths }/${ feedback360Routes.review.pathName }/${ formUploadId }?fromEmail=true`;
              } else {
                loadRouteAfterLogin = `/${ formPaths }/${ formUploadId }?fromEmail=true`;
              }

              this._router.navigate([appRoutes.login.fullPath], {
                queryParams: { loadRouteAfterLogin },
              });
            }
          } else if (errorCode === ErrorCode.TokenExpired ||
            errorCode === ErrorCode.RefreshTokenExpired) {
            showSnackbar = false;
            this._router.navigateByUrl(appRoutes.login.fullPath);
          } else if (errorCode === ErrorCode.AnonymousUser_DeclinedSubmission) {
            showSnackbar = false;
            this._router.navigate([appRoutes.declined.fullPath], {
              queryParams: { otherUserDeclined: true },
            });
          } else {
            this._router.navigate([appRoutes.login.fullPath], {
              queryParams: { loadRouteAfterLogin: this._router.url },
            });
          }

          if (showSnackbar) {
            this._matSnackBar.open(error?.title || this._localizationService.getTranslationByKey(errorMsgKey), '', {
              duration: 5000,
              panelClass,
            });
          }

          this._authService.clearEPALocalStorageKeys();
        } else if (requestStatus === 403) {
          const { errorCode } = error || {};

          let panelClass: string;
          const showSnackbar = true;

          if (errorCode === ErrorCode.AnonymousUser_SubmissionInvalidAssessor) {
            panelClass = 'snackbar-error';
          } else if (errorCode === ErrorCode.InvalidOwnerOrSupervisor) {
            panelClass = 'snackbar-error';
            this._router.navigateByUrl(appRoutes.dashboard.fullPath);
          }

          if (showSnackbar) {
            this._matSnackBar.open(error?.title, '', {
              duration: 5000,
              panelClass,
            });
          }
        } else if (requestStatus === 410) {
          const { errorCode } = error || {};

          let panelClass: string;
          const showSnackbar = true;

          if (errorCode === ErrorCode.SubmissionAlreadyCompleted
            || errorCode === ErrorCode.SubmissionIsRejected) {
            panelClass = 'snackbar-error';
          }

          if (showSnackbar) {
            this._matSnackBar.open(error?.title, '', {
              duration: 5000,
              panelClass,
            });
          }
        } else if (requestStatus === 400) {
          this._matSnackBar.open(error?.title, '', {
            duration: 5000,
            panelClass: 'snackbar-error',
          });
        }

        this._logAppInsights(req, error, requestStatus);

        return throwError(httpErrorResponse);
      }),
    );
  }

  private _logAppInsights(req: HttpRequest<any>, error: any, statusCode: number) {
    const errorCode = error?.errorCode;
    const errorTitle = error?.title;

    const properties: { [key: string]: any } = {};
    const paramKeys = req?.params?.keys();
    let params = '';
    if (paramKeys) {
      params = paramKeys.map(key => key + '=' + req?.params?.get(key)).join('&');
    }

    properties.Method = req?.method;
    properties.ErrorCode = errorCode;
    properties.ErrorMessage = errorTitle;
    properties.Params = params;

    const severity = statusCode >= 500 ? SeverityLevel.Error : SeverityLevel.Warning;

    this._applicationInsightsService.logTrace(`API error: ${ req?.url }`, severity, properties);
  }
}
