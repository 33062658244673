import {registerLocaleData} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import localeNL from '@angular/common/locales/nl';
import {LOCALE_ID, NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatMenuModule} from '@angular/material/menu';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CoreModule} from '@core/core.module';
import {LocalizationService} from '@core/services/localization.service';
import {dataServiceConfigProvider} from '@core/store/data/data-service.config';
import {httpUrlGeneratorProvider} from '@core/store/data/http-url-generator';
import {AppBlockerModule} from 'src/app/modules/shared/app-blocker/app-blocker.module';
import {SharedModule} from 'src/app/shared/shared.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

registerLocaleData(localeNL, 'nl');

const materials = [
  MatButtonModule,
  MatMenuModule,
  MatTooltipModule,
  MatSelectModule,
  MatDialogModule,
];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppBlockerModule,
    FlexLayoutModule,
    CoreModule,
    SharedModule,
    ...materials,
    AppRoutingModule,
  ],
  providers: [
    Title,
    dataServiceConfigProvider,
    httpUrlGeneratorProvider,
    {
      provide: LOCALE_ID,
      deps: [LocalizationService],
      useFactory: (localizationService: LocalizationService) => {
        return localizationService.getLanguageCodeLS();
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
