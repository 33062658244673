import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { environment } from '@env/environment';
import { AppBlockerService } from 'src/app/modules/shared/app-blocker/services/app-blocker.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApplicationInsightsService } from './application-insights.service';
import { LocationService } from './location.service';

export class NavigationState {
  id: number;
  url: string;
  navigationTrigger: string;
}

@Injectable()
export class RouterService implements OnDestroy {

  previousRoute: string;
  currentRoute: string;

  isAppBlockerShown: boolean;

  private _unsubscribe$ = new Subject<void>();
  private _currentNavigation$ = new BehaviorSubject<NavigationState>(null);

  get navigationChange$(): Observable<NavigationState> {
    return this._currentNavigation$.asObservable();
  }

  get currentNavigation(): NavigationState {
    return this._currentNavigation$.value;
  }

  constructor(
    private _locationService: LocationService,
    private _applicationInsightsService: ApplicationInsightsService,
    private _router: Router,
    private _appBlockerService: AppBlockerService,
  ) { }

  start() {
    this._appBlockerService.isShown$
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(isShown => this.isAppBlockerShown = isShown);

    this._router.events
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(e => {
        if (e instanceof NavigationStart) {
          if (this.isAppBlockerShown && e.navigationTrigger === 'popstate') {
            window.location.href = environment.baseUrl;
          }

          this._setHistoryToNavigationState(e);

          const { id, url, navigationTrigger } = e;
          this._currentNavigation$.next({ id, url, navigationTrigger });

          this._applicationInsightsService.startNavigationEvent(e.url);
        } else if (e instanceof NavigationEnd) {
          this.previousRoute = this.currentRoute;
          this.currentRoute = e.url;
          this._applicationInsightsService.endNavigationEvent(e.url);
          this._applicationInsightsService.logPageView(null, e.urlAfterRedirects);
        }
      });
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  private _setHistoryToNavigationState(e: NavigationStart) {
    // We manually update the navigation extras and update the state since route changes does not restore the state.
    const currentHistory = this._locationService.currentHistory;
    if (e.navigationTrigger === 'popstate' && currentHistory) {
      this._router.getCurrentNavigation().extras.state = currentHistory.state;
    }
  }
}
