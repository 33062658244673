import { Action, createReducer, on } from '@ngrx/store';
import { login, loginError, loginSuccess, reloginAfterIdle, reloginAfterIdleSuccess } from './auth.actions';
import { HttpUserAuth } from 'src/app/shared/models/account-api.model';

export interface IAuthState {
  loggedIn: boolean;
  loading: boolean;
  user: HttpUserAuth;
  error: string;
}

const initialAuthState: IAuthState = {
  loggedIn: false,
  loading: false,
  user: undefined,
  error: undefined,
};

const reducer = createReducer(
  initialAuthState,
  on(login, (state, action) => ({ ...state, loading: true, error: '' })),
  on(loginSuccess, (state, action) => ({
    ...state,
    user: action.userToken,
    loading: false,
    loaded: true,
    loggedIn: true,
  })),
  on(loginError, (state, action) => ({
    ...state,
    loading: false,
    loaded: false,
    loggedIn: false,
    error: action.httpError?.error?.title,
  })),
  on(reloginAfterIdle, state => ({ ...state, loading: true, error: '' })),
  on(reloginAfterIdleSuccess, state => ({ ...state, loading: false })),
);

export function authReducer(state: IAuthState, action: Action) {
  return reducer(state, action);
}
