import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { IAppState } from '@core/store/app.reducers';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectMemberStatus } from '@core/store/user-info/user-info.selectors';
import { map } from 'rxjs/internal/operators/map';
import { MemberStatusEnum } from 'src/app/shared/enums/member-status.enum';
import { filter, first, tap } from 'rxjs/operators';

@Injectable()
export class UserStateGuard implements CanActivate, CanActivateChild {

  constructor(private store: Store<IAppState>) { }

  canActivate(): Observable<boolean> {
    return this.store.pipe(
      select(selectMemberStatus),
      filter(status => status !== null && status !== undefined),
      map(status => status !== MemberStatusEnum.Cancelled),
      first(),
    );
  }

  canActivateChild(): Observable<boolean> {
    return this.canActivate();
  }

}
