import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Language } from 'src/app/shared/models/language.model';
import { Observable } from 'rxjs';

@Injectable()
export class LanguageHttpService {

  constructor(private http: HttpClient) { }

  getAllLanguages(): Observable<Language[]> {
    return this.http.get<Language[]>('client/Language/GetAll');
  }

}
