import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';

export interface IHttpDepartmentList {
  totalItems: number;
  index: number;
  limit: number;
  items: IDepartment[];
  sessionKey: string;
  totalPage: number;
}

export interface IDepartment {
  id: number;
  key: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class DepartmentHttpService {

  constructor(private http: HttpClient) {
  }

  getDepartments(userId: number): Observable<IHttpDepartmentList> {
    let params = new HttpParams();
    params = params.append('UserId', userId.toString());
    return this.http.get<IHttpDepartmentList>('client/Department', {params});
  }
}
