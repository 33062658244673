import {logout} from '@core/store/auth/auth.actions';
import {environment} from '@env/environment';
import {ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import {authReducer, IAuthState} from './auth/auth.reducer';
import {ILanguageState, languageReducer} from './langauge/language.reducer';
import {
  IPortfolioTypeConfigState,
  portfolioTypeConfigReducer,
} from './portfolio-type-config/portfolio-type-config.reducer';
import {IUserInfoState, userInfoReducer} from './user-info/user-info.reducer';
import {
  IDashboardState,
  dashboardReducer,
} from './dashboard/dashboard.reducer';
import {
  IEpaLibraryState,
  epaLibraryReducer,
} from './epa-library/epa-library.reducer';

export interface IAppState {
  auth?: IAuthState;
  userInfo?: IUserInfoState;
  language?: ILanguageState;
  dashboard?: IDashboardState;
  epaLibrary?: IEpaLibraryState;
  portfolioTypeConfig?: IPortfolioTypeConfigState;
}

export const reducers: ActionReducerMap<IAppState> = {
  auth: authReducer,
  userInfo: userInfoReducer,
  language: languageReducer,
  dashboard: dashboardReducer,
  epaLibrary: epaLibraryReducer,
  portfolioTypeConfig: portfolioTypeConfigReducer,
};

export function clearState(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action.type === logout.type) {
      const language = state.language;
      const dashboard = state.dashboard;
      const viewStudentPortfolio = state.viewStudentPortfolio;

      // Logout is called when viewing employee portfolio. We persist only the email when this happens because we need it.
      const {email} = state.auth;
      const auth = {email};

      state = undefined;
      state = {auth, language, dashboard, viewStudentPortfolio};
    }

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = environment.production
  ? [clearState]
  : [clearState];
