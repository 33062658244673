import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import Dexie, { IndexableType, Table } from 'dexie';

@Injectable()
export class IndexedDbService {

  db: Dexie;
  formUploadTableFilters: Table<any, IndexableType>;
  studentEpaProgressFilters: Table<any, IndexableType>;
  studentOverviewFilters: Table<any, IndexableType>;
  groupMembershipFilters: Table<any, IndexableType>;
  analyticsFeedbackFilters: Table<any, IndexableType>;
  internshipPlannerCalendarFilters: Table<any, IndexableType>;
  groupManagementOverviewFilters: Table<any, IndexableType>;
  individualEpasTableFilters: Table<any, IndexableType>;
  trainingProgramTableFilters: Table<any, IndexableType>;
  epaProgress: Table<any, IndexableType>;
  epaManagerFilter: Table<any, IndexableType>;
  bannerNotification: Table<any, IndexableType>;
  formUploadEverythingElseTable: Table<any, IndexableType>;
  formsPageFilter: Table<any, IndexableType>;

  init() {
    this.db = new Dexie(environment.indexedDb.name);

    this.db.version(6).stores({
      formUploadTableFilters: 'userId',
      studentEpaProgressFilters: 'userId',
      studentOverviewFilters: 'employeeId,portfolioGroupId',
      groupMembershipFilters: 'employeeId,portfolioGroupId',
    });

    this.db.version(7).stores({
      analyticsFeedbackFilters: 'employeeId,portfolioGroupId',
      internshipPlannerCalendarFilters: 'employeeId,portfolioGroupId',
      groupManagementOverviewFilters: '++id, [employeeId+portfolioGroupId+roleType]',
    });

    this.db.version(8).stores({
      formUploadTableFilters: 'employeeId,portfolioGroupId',
    });

    this.db.version(9).stores({
      individualEpasTableFilters: 'userId',
    });

    this.db.version(10).stores({
      trainingProgramTableFilters: 'userId',
    });

    this.db.version(11).stores({
      epaProgress: '++id, employeeId, epaId, portfolioTypeId, specialityId, levelOfCompetence, status',
      epaProgressTrainingPrograms: '++id, employeeId, portfolioTypeId, status',
    });

    this.db.version(12).stores({
      epaProgressTrainingPrograms: null, // Delete
    });

    this.db.version(13).stores({
      formUploadTableFilters: 'employeeId,portfolioTypeId',
      studentOverviewFilters: 'employeeId,portfolioTypeId',
      analyticsFeedbackFilters: 'employeeId,portfolioTypeId',
      internshipPlannerCalendarFilters: 'employeeId,portfolioTypeId',
      groupMembershipFilters: 'employeeId,portfolioTypeId',
      groupManagementOverviewFilters: '++id, [employeeId+portfolioTypeId+roleType]',
    });

    this.db.version(14).stores({
      epaManagerFilter: 'userId',
    });

    this.db.version(15).stores({
      bannerNotification: 'userId',
    });

    this.db.version(16).stores({
      formUploadEverythingElseTable: 'employeeId, portfolioTypeId',
    });

    this.db.version(17).stores({
      formsPageFilter: 'employeeId, portfolioTypeId',
    });

    this.formUploadTableFilters = this.db.table('formUploadTableFilters');
    this.studentEpaProgressFilters = this.db.table('studentEpaProgressFilters');
    this.studentOverviewFilters = this.db.table('studentOverviewFilters');
    this.groupMembershipFilters = this.db.table('groupMembershipFilters');
    this.analyticsFeedbackFilters = this.db.table('analyticsFeedbackFilters');
    this.internshipPlannerCalendarFilters = this.db.table('internshipPlannerCalendarFilters');
    this.groupManagementOverviewFilters = this.db.table('groupManagementOverviewFilters');
    this.individualEpasTableFilters = this.db.table('individualEpasTableFilters');
    this.trainingProgramTableFilters = this.db.table('trainingProgramTableFilters');
    this.epaProgress = this.db.table('epaProgress');
    this.epaManagerFilter = this.db.table('epaManagerFilter');
    this.bannerNotification = this.db.table('bannerNotification');
    this.formUploadEverythingElseTable = this.db.table('formUploadEverythingElseTable');
    this.formsPageFilter = this.db.table('formsPageFilter');
  }

  purge() {
    this.db.delete();
  }

}
