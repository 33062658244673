import { Injectable, OnDestroy } from '@angular/core';
import { AppVersionHttpService } from '@core/https/app-version-http.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IndexedDbService } from '@core/services/indexed-db.service';
import { Store } from '@ngrx/store';
import { IAppState } from '@core/store/app.reducers';
import { logout } from '@core/store/auth/auth.actions';
import { MatDialog } from '@angular/material/dialog';
import {
  DifferentVersionDialogComponent
} from 'src/app/shared/components/different-version-dialog/different-version-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { USER_ROLE_ID_LS_KEY } from '@core/services/auth.service';
import { UserRole } from 'src/app/shared/enums/user-role.enum';
import { UrlService } from '@core/services/url.service';

export const RELEASE_VERSION_LS_KEY = 'epa_app_version';

@Injectable()
export class AppVersionService implements OnDestroy {

  private static _blacklistedPageUrls: string[] = [
    '/',
    '/auth/reset-password',
    '/anonymous',
    '/token',
    '/invitation',
    '/invitation/confirmation',
    '/invitation/declined',
    '/invitation/email-confirmed',
    '/form-completed',
    '/form-deleted',
    '/form-void',
    '/user-already-been-linked',
    '/assessor-removed',
  ];

  private _unsubscribe$ = new Subject();

  constructor(
    private _store: Store<IAppState>,
    private _appVersionHttpService: AppVersionHttpService,
    private _indexedDbService: IndexedDbService,
    private _matDialog: MatDialog,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _urlService: UrlService,
  ) {}

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  checkServerVersion() {
    this._appVersionHttpService.getReleaseVersion()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(version => this.checkAppVersionIfMatched(version));
  }

  checkAppVersionIfMatched(serverVersion: string) {
    const storedAppVersion: string | null | undefined = localStorage.getItem(RELEASE_VERSION_LS_KEY);
    // IMPORTANT: Check if null only => storedAppVersion === null
    if (storedAppVersion === null || storedAppVersion !== serverVersion) {
      const currentRoute: string = window.location.pathname;
      if (!AppVersionService._blacklistedPageUrls.some(x => x.includes(currentRoute)) && !this.isAnonymousUser()) {
        if (currentRoute.indexOf('/auth/login') >= 0) {
          this._store.dispatch(logout({
            hardReloadAfter: true,
            queryParamsHandling: 'merge',
            waitNavigationEnd: true,
          }));
          this._indexedDbService.purge();
        } else {
          this._matDialog.open(DifferentVersionDialogComponent, { disableClose: true })
            .afterClosed()
            .pipe(takeUntil(this._unsubscribe$))
            .subscribe(() => {
              this._store.dispatch(logout({
                hardReloadAfter: true,
                queryParamsHandling: 'merge',
                waitNavigationEnd: true,
              }));
              this._indexedDbService.purge();
            });
        }
      }
    }
    localStorage.setItem(RELEASE_VERSION_LS_KEY, serverVersion);
  }

  private isAnonymousUser(): boolean {
    const roleId: number = +this._urlService.getCurrentRouteParams().roleId;
    const roleIdFromLocalStorage: number = +localStorage.getItem(USER_ROLE_ID_LS_KEY);
    return roleId === UserRole.Anonymous || roleIdFromLocalStorage === UserRole.Anonymous;
  }

}
