export const qEpaId = 'eid';
export const qSubEpaId = 'seid';
export const qEmployeeId = 'sid';
export const qStudentOrSupervisorId = 'ssid';
export const qFormId = 'fid';
export const qFormSubTypeId = 'fstid';
export const qParentFormSubTypeId = 'pfstid';
export const qEpaSubEpaId = 'eseid';
export const qLang = 'lang';
export const qRedirectUrl = 'redirectUrl';
export const qStudentIndex = 'sidx';
export const qLoadRouteAfterLogin = 'loadRouteAfterLogin';
export const qRelogin = 'reLogin';
export const qHasUnsavedChanges = 'huc';
export const qPortfolioGroupMemberId = 'pgid';
export const qPortfolioTypeId = 'ptid';
export const qRoleId = 'roid';
export const qFormOnly = 'formOnly';
export const qSurfConext = 'surfconext';
export const qOtherUserDeclined = 'otherUserDeclined';
