import { Action, createReducer, on } from '@ngrx/store';
import { LibraryFilters } from 'src/app/shared/models/filters-list.model';
import {
  BasicEPAInformation,
  ChartOverview,
  EpaLibraryData,
} from 'src/app/shared/models/epa-library-data.model';
import {
  getBasicEPAInformationSuccess,
  getChartOverviewSuccess,
  getEPAInformationSuccess,
  getEmployeeAssignedEPASuccess,
  getLibraryDataSuccess,
  setBasicInfoLoaded,
  setDataLoaded,
  setUpdateLoaded,
  updateEmployeesAssignedEpaSuccess,
} from './epa-library.actions';
import { EPAInformation } from 'src/app/shared/models/epa-information.model';
import { EmployeeAssignedEPA } from 'src/app/shared/models/employee-assigned-epa.model';

export interface IEpaLibraryState {
  epaLibraryData: EpaLibraryData;
  basicEPAInformation: BasicEPAInformation;
  epaInformation: EPAInformation;
  employeeAssignedEPA: EmployeeAssignedEPA;
  chartOverview: ChartOverview;
  libraryFilters: LibraryFilters;

  libraryLoaded: boolean;
  response: any;

  updateLoaded: boolean;
  basicEPAInformationLoaded: boolean;
}

const initialDashboardState: IEpaLibraryState = {
  epaLibraryData: undefined,
  basicEPAInformation: undefined,
  epaInformation: undefined,
  employeeAssignedEPA: undefined,
  libraryFilters: undefined,
  chartOverview: undefined,

  libraryLoaded: false,
  response: undefined,

  updateLoaded: true,
  basicEPAInformationLoaded: true,
};

const reducer = createReducer(
  initialDashboardState,
  on(getChartOverviewSuccess, (state, action) => ({
    ...state,
    chartOverview: action.chartOverview,
  })),
  on(getLibraryDataSuccess, (state, action) => ({
    ...state,
    epaLibraryData: action.epaLibraryData,
  })),

  on(getBasicEPAInformationSuccess, (state, action) => ({
    ...state,
    basicEPAInformation: action.basicEPAInformation,
  })),

  on(getEPAInformationSuccess, (state, action) => ({
    ...state,
    epaInformation: action.epaInformation,
  })),
  on(getEmployeeAssignedEPASuccess, (state, action) => ({
    ...state,
    employeeAssignedEPA: action.data,
  })),

  on(updateEmployeesAssignedEpaSuccess, (state, action) => {
    return {
      ...state,
      response: action.response,
      employeeAssignedEPA: action.employeeAssignedEPA,
    };
  }),

  on(setDataLoaded, (state, action) => ({
    ...state,
    libraryLoaded: action.loaded,
  })),

  on(setUpdateLoaded, (state, action) => ({
    ...state,
    updateLoaded: action.updateLoaded,
  })),
  on(setBasicInfoLoaded, (state, action) => ({
    ...state,
    basicEPAInformationLoaded: action.loadedBasicInfo,
  }))
);

export function epaLibraryReducer(state: IEpaLibraryState, action: Action) {
  return reducer(state, action);
}
