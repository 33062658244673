import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {
  AdvanceDialogActionsDirective,
  AdvanceDialogComponent,
  AdvanceDialogContentDirective,
  AdvanceDialogHeaderDirective,
} from './advance-dialog/advance-dialog.component';

const components = [
  AdvanceDialogComponent,
];

const materials = [
  MatDialogModule,
  MatButtonModule,
  MatIconModule,
];

const directives = [
  AdvanceDialogHeaderDirective,
  AdvanceDialogContentDirective,
  AdvanceDialogActionsDirective,
];

@NgModule({
  declarations: [
    ...components,
    ...directives,
  ],
  imports: [
    ...materials,
    CommonModule,
    FlexLayoutModule,
  ],
  exports: [
    ...components,
    ...directives,
  ],
})
export class AdvanceDialogModule {
}
