import {Location} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {IAppState} from '@core/store/app.reducers';
import {login, logout} from '@core/store/auth/auth.actions';
import {environment} from '@env/environment';
import {Store} from '@ngrx/store';
import {IUserAuth, IUserCredential} from '@regular-page-modules/auth/auth.model';
import {LostDataDialogComponent} from 'src/app/shared/components/lost-data-dialog/lost-data-dialog.component';
import {appRoutes} from 'src/app/shared/enums/app-routes.enum';
import {ErrorCode} from 'src/app/shared/enums/error-code.enum';
import {qHasUnsavedChanges, qLoadRouteAfterLogin, qRelogin} from 'src/app/shared/query-param-ids';
import {BehaviorSubject, Observable, Subject, throwError} from 'rxjs';
import {catchError, takeUntil, tap} from 'rxjs/operators';
import {LocalizationService} from './localization.service';
import {TokenExpiryService} from './token-expiry.service';
import {AccountHttpService} from '@core/https/account-http.service';
import {HttpUserAuth, HttpUserInfo} from 'src/app/shared/models/account-api.model';

export const USER_ROLE_ID_LS_KEY = 'epa_role_id';
export const USER_ID_LS_KEY = 'epa_user_id';
export const USER_NAME_LS_KEY = 'epa_username';
export const USER_TOKEN_LS_KEY = 'epa';
export const USER_LOGIN_TYPE = 'epa_login_type';

export const SESSION_TOKEN_KEY = 'epa_token_key';
export const SESSION_COUNT = 'app_session_count';

@Injectable()
export class AuthService implements OnDestroy {

  get refreshTokenSubject$(): Observable<IUserAuth> {
    return this._refreshTokenSubject$.asObservable();
  }

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _tokenExpiryService: TokenExpiryService,
    private _accountHttpService: AccountHttpService,
    private _router: Router,
    private _matDialog: MatDialog,
    private _store: Store<IAppState>,
  ) {
    this._activatedRoute.queryParamMap
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(params => {
        const loadRouteAfterLogin = params.get(qLoadRouteAfterLogin);
        this._loadRoute = params.get(qLoadRouteAfterLogin) ? unescape(loadRouteAfterLogin) : appRoutes.dashboard.fullPath;

        this.isReLoggingIn = params.get(qRelogin) === 'true';

        const hasUnsavedChanges = params.get(qHasUnsavedChanges) === 'true';
        if (hasUnsavedChanges) {
          this._matDialog.open(LostDataDialogComponent, {
            panelClass: 'base-dialog',
            width: '100%',
            maxWidth: '700px',
            role: 'alertdialog',
            closeOnNavigation: true,
          });
        }
      });
  }

  private static _blacklistedSessionPageUrls: string[] = [
    '/auth/reset-password',
    '/anonymous',
    '/token',
    '/invitation',
    '/invitation/confirmation',
    '/invitation/declined',
    '/invitation/email-confirmed',
    '/form-completed',
    '/form-deleted',
    '/form-void',
    '/user-already-been-linked',
    '/assessor-removed',
  ];

  // Note: portfolioGroupId is only used in Caching Service
  // TODO: Update this to PortfolioType /+ Speciality
  portfolioGroupId: undefined;
  portfolioTypeId: number;

  userId: number;
  isReLoggingIn: boolean;
  refreshTokenInProgress = false;

  private _refreshTokenSubject$: BehaviorSubject<IUserAuth> = new BehaviorSubject<IUserAuth>(null);
  private _loadRoute: string;
  private _unsubscribe$ = new Subject<void>();

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  userTokenLS(): HttpUserAuth | undefined {
    return JSON.parse(localStorage.getItem(USER_TOKEN_LS_KEY)) || undefined;
  }

  userTokenSS(): string | null {
    return sessionStorage.getItem(SESSION_TOKEN_KEY);
  }

  userRoleIdLS(): number | undefined {
    return +localStorage.getItem(USER_ROLE_ID_LS_KEY) || undefined;
  }

  userIdLS(): number | undefined {
    return +localStorage.getItem(USER_ID_LS_KEY) || undefined;
  }

  usernameLS(): string | undefined {
    return localStorage.getItem(USER_NAME_LS_KEY) || undefined;
  }

  setUserTokenAndSession(userToken: HttpUserAuth) {
    localStorage.setItem(USER_TOKEN_LS_KEY, JSON.stringify(userToken));
    sessionStorage.setItem(SESSION_TOKEN_KEY, JSON.stringify(this.userTokenLS().token));
  }

  setUserRoleIdLS(userRoleId: number) {
    if (this.userRoleIdLS() === userRoleId) {
      return;
    }

    localStorage.setItem(USER_ROLE_ID_LS_KEY, userRoleId.toString());
  }

  setUserIdLS(userId: number) {
    if (this.userIdLS() === userId) {
      return;
    }

    localStorage.setItem(USER_ID_LS_KEY, JSON.stringify(userId));
  }

  setUsernameLS(username: string) {
    localStorage.setItem(USER_NAME_LS_KEY, username);
  }

  clearUsernameLS() {
    if (!this.usernameLS()) {
      return;
    }

    localStorage.removeItem(USER_NAME_LS_KEY);
  }

  isTokenTheSame() {
    if (!this.userTokenSS() || !this.isUserTokenLSValid()) {
      return true;
    }
    return this.userTokenLS().token === this.userTokenSS();
  }

  isUserTokenLSValid(): boolean {
    const auth = this.userTokenLS();
    if (auth && auth.expiration) {
      const expTimestamp = Date.parse(auth.expiration);
      const nowTimestamp = Date.now();
      return expTimestamp > nowTimestamp;
    }

    return false;
  }

  clearEPALocalStorageKeys() {
    this.clearUserTokenLS();

    localStorage.removeItem(USER_ROLE_ID_LS_KEY);
  }

  clearUserTokenLS() {
    // Only clear tokens if it is not cleared yet to avoid triggering the storage event in browsers.
    if (!this.userTokenLS()) {
      return;
    }

    localStorage.removeItem(USER_TOKEN_LS_KEY);

    sessionStorage.removeItem(SESSION_TOKEN_KEY);
  }

  refreshToken(): Observable<IUserAuth> {
    this.refreshTokenInProgress = true;
    this._refreshTokenSubject$.next(null);

    const {token, refreshToken} = this.userTokenLS();
    return this._accountHttpService.refreshToken({token, refreshToken})
      .pipe(
        tap((newAuth: IUserAuth) => {
          // Update existing token
          this.setUserTokenAndSession(newAuth);
          this._tokenExpiryService.setExpiration(
            newAuth.expiration,
            environment.idle.forcedReloginAfterNoResponseInSeconds * 1000,
          );
          this.refreshTokenInProgress = false;
          this._refreshTokenSubject$.next(newAuth);
        }),
        catchError((httpErrorResponse: HttpErrorResponse) => {
          this.clearEPALocalStorageKeys();
          window.location.reload();

          return throwError(httpErrorResponse);
        }),
      );
  }

  getCurrentInfo(): Observable<HttpUserInfo> {
    return this._accountHttpService.getCurrentInfo();
  }

  login(userCredential?: IUserCredential, surfconext?: string, loadRoute?: string) {
    if (!loadRoute) {
      loadRoute = this._loadRoute;
    }

    const {username} = userCredential;
    this.setUsernameLS(username);

    this._store.dispatch(login({userCredential, surfconext, loadRoute}));
  }

  loginAnonymously(token: string): Observable<HttpUserAuth> {
    this.clearEPALocalStorageKeys();

    // TODO: Temporarily disable app blocker due to idle. User's token is immediately refreshed.
    this._tokenExpiryService.appBlockerEnabled = false;

    return this._accountHttpService.loginAnonymously(token)
      .pipe(
        catchError((httpErrorResponse: HttpErrorResponse) => {
          const {error} = httpErrorResponse;
          const requestStatus = httpErrorResponse.status || error.status;
          const errorCode = error?.errorCode;

          if (requestStatus === 410) {
            if (errorCode === ErrorCode.SubmissionIsDeleted ||
              errorCode === ErrorCode.SubmissionNotFound) {
              this._router.navigateByUrl(appRoutes.formDeleted.fullPath);
            }
          }

          return throwError(httpErrorResponse);
        }),
      );
  }

  sessionInspector() {
    this.registerSession();
    this.evaluateSession();

    window.addEventListener('beforeunload', this.unregisterSession);
  }

  evaluateSession() {
    const currentRoute: string = window.location.pathname;
    if (!environment.browserSessionSettings.logoutOnClose ||
      AuthService._blacklistedSessionPageUrls.some(x => currentRoute.includes(x))) {
      return;
    }

    if (this.isUserTokenLSValid()) {
      const sessionToken: string | null = sessionStorage.getItem(SESSION_TOKEN_KEY);
      if (sessionToken && !this.isTokenTheSame()) {
        sessionStorage.setItem(SESSION_TOKEN_KEY, JSON.stringify(this.userTokenLS().token));
      } else {
        const appSessionCount: string = localStorage.getItem(SESSION_COUNT);
        if (+appSessionCount > 1) {
          sessionStorage.setItem(SESSION_TOKEN_KEY, JSON.stringify(this.userTokenLS().token));
        } else {
          this._store.dispatch(logout({queryParamsHandling: 'merge', waitNavigationEnd: true}));
        }
      }
    } else {
      sessionStorage.removeItem(SESSION_TOKEN_KEY);
    }
  }

  private registerSession() {
    const appSessionCount: string = localStorage.getItem(SESSION_COUNT);
    localStorage.setItem(SESSION_COUNT, (appSessionCount === null || +appSessionCount <= 0 ? 1 : +appSessionCount + 1).toString());
  }

  private unregisterSession() {
    const appSessionCount: string = localStorage.getItem(SESSION_COUNT);
    localStorage.setItem(SESSION_COUNT, (appSessionCount === null || +appSessionCount <= 0 ? 0 : +appSessionCount - 1).toString());
  }

}
