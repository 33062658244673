import {Component, Directive, EventEmitter, Output} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Directive({
  selector:
    'xf-advance-dialog-header, [xf-advance-dialog-header], [advanceDialogHeader]',
  host: {class: 'xf-advance-dialog-header'},
})
export class AdvanceDialogHeaderDirective {
}

@Directive({
  selector:
    'xf-advance-dialog-content, [xf-advance-dialog-content], [advanceDialogContent]',
  host: {class: 'xf-advance-dialog-content'},
})
export class AdvanceDialogContentDirective {
}

@Directive({
  selector:
    'xf-advance-dialog-actions, [xf-advance-dialog-actions], [advanceDialogActions]',
  host: {class: 'xf-advance-dialog-actions'},
})
export class AdvanceDialogActionsDirective {
}

@Component({
  selector: 'xf-dialog-container',
  templateUrl: './epa-dialog-container.component.html',
  styleUrls: ['./epa-dialog-container.component.scss'],
})
export class EpaDialogContainerComponent {
  @Output()
  closeDialog = new EventEmitter<boolean>();

  constructor(public dialog: MatDialogRef<EpaDialogContainerComponent>) {
  }
}
