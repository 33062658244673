import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  HttpUserAuth,
  HttpUserInfo,
  IPasswordReset,
  IRefreshTokenRequest,
  IUserCredential
} from 'src/app/shared/models/account-api.model';

@Injectable()
export class AccountHttpService {

  constructor(private _http: HttpClient) { }

  refreshToken(refreshTokenRequest: IRefreshTokenRequest) {
    return this._http.post<HttpUserAuth>('client/Accounts/RefreshToken', refreshTokenRequest);
  }

  requestResetPassword(email: string): Observable<any> {
    return this._http.post('client/Accounts/GeneratePasswordResetToken', email);
  }

  resetPassword(passwordReset: IPasswordReset, token: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${ token }`);
    passwordReset.token = token;
    return this._http.post('client/Accounts/ResetPassword', { ...passwordReset }, { headers });
  }

  getCurrentInfo(): Observable<HttpUserInfo> {
    return this._http.get<HttpUserInfo>('client/Accounts/GetCurrentInfo');
  }

  generateTokenStudentMode(employeeId: number): Observable<HttpUserAuth> {
    return this._http.post<HttpUserAuth>('client/Accounts/GenerateTokenStudentMode', { employeeId });
  }

  generateTokenSwitchBackToOwnProfile(): Observable<HttpUserAuth> {
    return this._http.post<HttpUserAuth>('client/Accounts/GenerateTokenSwitchBackToOwnProfile', null);
  }

  getUserTokenOnSwitchPortfolioType(portfolioTypeId: number, roleId: number): Observable<HttpUserAuth> {
    let params = new HttpParams();
    params = params.append('PortfolioTypeId', portfolioTypeId.toString());
    params = params.append('RoleId', roleId.toString());

    return this._http.get<HttpUserAuth>('client/Accounts/SwitchPortfolioType', { params });
  }

  login(userCredential: IUserCredential): Observable<HttpUserAuth> {
    return this._http.post<HttpUserAuth>('client/Accounts/GenerateToken', { ...userCredential });
  }

  loginWithSurfConext(code: string): Observable<HttpUserAuth> {
    return this._http.post<HttpUserAuth>('client/Accounts/SurfConextGenerateToken', { code });
  }

  loginAnonymously(token: string): Observable<HttpUserAuth> {
    return this._http.post<HttpUserAuth>('client/Accounts/GenerateFormSubmissionToken', { token });
  }

  logout(): Observable<any> {
    return this._http.post<any>('client/Accounts/Logout', {});
  }

}
