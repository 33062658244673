import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  cacheActivityMonitor,
  cacheEpaProgress,
  cacheStudentOverview,
  cacheStudentOverviewDialog,
  cacheStudentOverviewPages,
  cacheTotalPages,
  cacheTrainingProgramProgress
} from 'src/app/shared/cache-keys';
import { AuthService } from './auth.service';
import { LocalizationService } from './localization.service';

@Injectable()
export class CacheKeyService {

  constructor(
    private authService: AuthService,
    private localizationService: LocalizationService) {}

  getRequestCacheKey(request: HttpRequest<any>) {
    const {
      url,
      urlWithParams,
    } = request;
    const userKey = this.getLoggedInUserKey();
    return `${ userKey }-${ url }-${ urlWithParams }`;
  }

  getTrainingProgramProgressKey(employeeId: number) {
    const userKey = this.getLoggedInUserKey();
    return `${ userKey }-${ cacheTrainingProgramProgress }-${ employeeId }`;
  }

  getEpaProgressKey(employeeId: number) {
    const userKey = this.getLoggedInUserKey();
    return `${ userKey }-${ cacheEpaProgress }-${ employeeId }`;
  }

  getEpaProgressTotalPagesKey(employeeId: number) {
    const userKey = this.getLoggedInUserKey();
    return `${ userKey }-${ cacheEpaProgress }-${ cacheTotalPages }-${ employeeId }`;
  }

  getActivityMonitorKey(studentIndex: number, employeeId: number) {
    const userKey = this.getLoggedInUserKey();
    return `${ userKey }-${ cacheStudentOverviewDialog }-${ cacheActivityMonitor }-${ studentIndex }-${ employeeId }`;
  }

  getStudentOverviewKey(studentIndex: number) {
    const userKey = this.getLoggedInUserKey();
    return `${ userKey }-${ cacheStudentOverviewDialog }-${ cacheStudentOverview }-${ studentIndex }`;
  }

  getStudentOverviewPagesKey() {
    const userKey = this.getLoggedInUserKey();
    return `${ userKey }-${ cacheStudentOverviewDialog }-${ cacheStudentOverview }-${ cacheStudentOverviewPages }`;
  }

  getLoggedInUserKey() {
    const userId = this.authService.userId;
    // TODO: Since there will be no portfolio group, we will change this to portfolio type soon.
    const portfolioTypeId = this.authService.portfolioTypeId;
    const roleId = this.authService.userRoleIdLS() || 0;
    const lang = this.localizationService.getLanguageCodeLS();

    if (!userId || !portfolioTypeId || !roleId || !lang) {
      return '';
    }

    return `${ userId }-${ portfolioTypeId }-${ roleId }-${ lang }`;
  }
}
