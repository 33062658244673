import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

export interface IHttpEmployeeList {
  totalItems: number;
  index: number;
  limit: number;
  items: IEmployee[];
  sessionKey: string;
  totalPage: number;
}

export interface IEmployee {
  id: number;
  key: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class EmployeeHttpService {

  constructor(private http: HttpClient) {
  }

  getEmployeesByDepartment(departmentId: number): Observable<IHttpEmployeeList> {
    let params = new HttpParams();
    params = params.append('DepartmentId', departmentId.toString());
    return this.http.get<IHttpEmployeeList>('client/Employee/SearchEmployeeByDepartment', {params});
  }
}
