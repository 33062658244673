import { MemberSetting } from '@client-shared-modules/models/member-setting.model';
import { Action, createReducer, on } from '@ngrx/store';
import { MemberStatusEnum } from 'src/app/shared/enums/member-status.enum';
import { PortfolioTypeEnum } from 'src/app/shared/enums/portfolio-type.enum';
import { ImageInfo } from 'src/app/shared/models/image-info.model';
import { getCurrentInfo, getCurrentInfoError, getCurrentInfoSuccess } from './user-info.actions';

export interface IUserInfoState {
  id: number;
  loaded: boolean;
  loading: boolean;
  userTitle: string;
  firstName: string;
  surName: string;
  middleName: string;
  email: string;
  portfolioGroupName: string;
  roleName: string;
  roleId: number;
  unreadNotificationCount: number;
  myReviewCount: number;
  lastActivityDate: string;
  localizationLanguages: string[];
  currentYear: number;
  currentSemester: number;
  portfolioGroupMemberId: number;
  groupMemberships: IUserInfoGroupMembership[];
  availableSwitchProfile: IUserAvailableSwitchProfile[];
  userProfile: IUserProfile;
  currentLanguageCode: string;
  hasInternshipSchedule: boolean;
  groupInfo: {
    hasHeadTeachers: boolean;
    hasTeachers: boolean;
    hasStudents: boolean;
    hasTrainingProgram: boolean;
    memberStatus: MemberStatusEnum;
    specialityName: string;
  };
  viewMode: boolean;
  viewModeBy: 'None' | 'HeadTeacher' | 'Teacher';
  viewModeByName: string;
  viewModeByProfilePhotoUrl: string;
  hasLinkMembers: boolean;
  profilePhotoUrl: string;
  isMentor: boolean;
  portfolioTypeId: PortfolioTypeEnum;
  viewModeByUserId: number;
  hasTrainingProgram: boolean;
  todoCounts: {
    assessmentRequestCount: number;
    epaRequestCount: number;
    reviewNewlyCreateEpaCount: number;
    systemActionCount: number;
    todoTotalCount: number;
  }
}

export interface IUserProfile {
  firstName: string;
  middleName: string;
  surName: string;
  gender: string;
  email: string;
  levelOfTrainingId: number;
  levelOfTrainingName: string;
  categoryId: number;
  categoryName: string;
  companyId: number;
  companyName: string;
  institutionType: string;
  institutionTypeName: string;
  hospitalId: number;
  hospitalName: string;
  hboId: number;
  hboName: string;
  schoolId: number;
  schoolName: string;
  countryId: number;
  countryName: string;
  userTitleId: number;
  userTitleName: string;
  languageId: number;
  languageName: string;
  currentYear: number;
  currentSemester: number;
  picture: ImageInfo;
  memberSetting: MemberSetting;

  // TODO: Remove Later
  specialityName?: string;
}

export interface IUserAvailableSwitchProfile {
  roleId: number;
  roleName: string;
  portfolioTypeId: number;
  portfolioTypeName: string;
  fullAccess: boolean;
}

export interface IUserInfoGroupMembership {
  portfolioGroupMemberId: number;
  portfolioTypeId: number;
  portfolioTypeName: string;
  portfolioGroupName: string;
  specialityName: string;
  role: string;
  roleId: number;
  status: string;
  memberDate: string;
  cancelledDate: string;
  statusEnum: string;
}

const initialUserInfoState: IUserInfoState = {
  id: undefined,
  loaded: false,
  loading: false,
  userTitle: undefined,
  firstName: undefined,
  surName: undefined,
  middleName: undefined,
  email: undefined,
  portfolioGroupName: undefined,
  roleName: undefined,
  roleId: undefined,
  unreadNotificationCount: undefined,
  myReviewCount: undefined,
  lastActivityDate: undefined,
  localizationLanguages: undefined,
  currentYear: undefined,
  currentSemester: undefined,
  userProfile: undefined,
  portfolioGroupMemberId: undefined,
  groupMemberships: undefined,
  availableSwitchProfile: undefined,
  currentLanguageCode: undefined,
  groupInfo: undefined,
  viewMode: undefined,
  viewModeBy: undefined,
  hasInternshipSchedule: undefined,
  hasLinkMembers: undefined,
  profilePhotoUrl: undefined,
  isMentor: undefined,
  portfolioTypeId: undefined,
  viewModeByName: undefined,
  viewModeByProfilePhotoUrl: undefined,
  viewModeByUserId: undefined,
  hasTrainingProgram: undefined,
  todoCounts: undefined,
};

const reducer = createReducer(
  initialUserInfoState,
  on(getCurrentInfo, state => ({ ...state, loading: true })),
  on(getCurrentInfoSuccess, (state, action) => ({ ...state, ...action.userInfo, loaded: true, loading: false })),
  on(getCurrentInfoError, state => ({ ...state, loaded: false, loading: false })),
);

export function userInfoReducer(state: IUserInfoState, action: Action) {
  return reducer(state, action);
}
